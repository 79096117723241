
import React, { useState } from 'react';
import axios from 'axios';
import {Select, SelectItem, Button, Spinner, Divider} from "@nextui-org/react";

const GifUploader = () => {
  document.title = "Upload Gif";
  const [image, setImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);

  const config = {
    'COVERED_GENITALIA_F': { 'cen': 'pixelate_light', 'name': 'Covered genitalia F' }, 
    'EXPOSED_GENITALIA_F': { 'cen': 'pixelate', 'name': 'Genitalia F' }, 
    'COVERED_BREAST_F': { 'cen': 'pixelate_light', 'name': 'Covered breasts' }, 
    'EXPOSED_BREAST_F': { 'cen': 'pixelate', 'name': 'Breasts' }, 
    'EXPOSED_BUTTOCKS': { 'cen': 'pixelate', 'name': 'Butt' }, 
    'EYE_F': { 'cen': 'none', 'name': 'Eyes F' }, 
    'FACE_F': { 'cen': 'none', 'name': 'Face F' }, 
    'EXPOSED_GENITALIA_M': { 'cen': 'none', 'name': 'Genitalia M' }, 
  };
  const options = ['none', 'pixelate', 'bar', 'pixelate_light', 'pixelate_very_light', 'pixelate_hard'];

  const [configData, setConfigData] = useState(config);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setImage({file: file, filename: event.target.files[0].name});
    setPreviewImage(null);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsUploading(true);

    const formData = new FormData();
    formData.append('image', image.file);
    formData.append('config', JSON.stringify(configData));
    setError(null);
    let apiEndpoint = "upload/gif";
    if (previewImage) {
      apiEndpoint = "upload_existing/gif";
    }
    try {
      // const response = await axios.post('http://127.0.0.1:8000/api/'+apiEndpoint, formData, {
      const response = await axios.post(process.env.REACT_APP_CN_API_ADDRESS+'/api/'+apiEndpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: 'blob',
      });
      setPreviewImage(URL.createObjectURL(response.data));
      console.log('Image uploaded successfully');
    } catch (error) {
      console.error('Error uploading image:', error);
      if ('response' in error) {
        let errormessage = JSON.parse(await error.response.data.text());
        if ("detail" in errormessage) {
          setError({"status": error.response.statusText, "error": errormessage.detail});
        } else {
          setError({"status": error.response.statusText, "error": errormessage.error});
        }
      } else {
        setError({"status": "No response from server", "error": error.message});
      }
    } finally {
      setIsUploading(false);
    }
  };

  const handleChange = (key, value) => {
    setConfigData({
      ...configData,
      [key]: { cen: value, name: configData[key].name }
    });
  };

  return (
<form onSubmit={handleSubmit} className="flex w-full pb-3 max-h-full mx-auto divide-x">
  <div className="w-3/4 pr-4 max-h-full">
    <h2 className="text-3xl font-bold mb-4 dark:text-white text-black">Censor Gif</h2>
    <h3 className="text-lg font-normal mb-4 dark:text-white text-black">Select your image, choose what you want censored and press "Upload"
      <span className="text-xs ml-2"> By uploading you agree to our <a href="/terms"><span className="font-semibold text-purple-400">terms and conditions</span></a>
      </span>
    </h3>
    {error && (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-xl relative my-3 dark:bg-red-900 dark:text-red-300" role="alert">
        <strong className="font-bold">{error.status}</strong>
        <span className="block sm:inline ml-2">{error.error}</span>
        <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </span>
      </div>
    )}
    <div className="mb-4 flex">
      <label
        htmlFor="image-upload"
        // className="block text-gray-700 font-bold mb-2"
        className="flex items-center py-1 px-2 gap-3 w-full rounded-3xl border border-gray-300 border-dashed bg-gray-50 cursor-pointer dark:bg-gray-700 dark:text-white"
      >
      {/* <FontAwesomeIcon icon={faUpload} size="2x" className="mt-3 mb-2 ml-3" /> */}
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-8">
        <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
      </svg>

      
      <div className="space-y-1">

        <h4 className="text-base font-semibold text-gray-700 dark:text-white">
        {image && image.filename ? (
          image.filename
        ) : (
          "Select Image"
        )}
        </h4>

        <span className="text-sm text-gray-500">Max 10 MB</span>

      </div>
      <input
        id="image-upload"
        type="file"
        onChange={handleImageChange}
        hidden
        // className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
      />
      </label>
      <Button
        className="bg-blue-500 my-auto hover:bg-blue-700 text-white font-bold py-2 px-4 ml-4"
        type="submit"
        size="lg"
        >
        {isUploading ? (
          <Spinner />
        ) : (
          "Upload"
        )}
      </Button>
      {previewImage && (
      <Button
        className="bg-green-500 my-auto hover:bg-green-700 text-white font-bold py-2 px-4 ml-4"
        size="lg"
      >
        <a download={image.filename} href={previewImage}>
          Download
        </a>
      </Button>
      )}
    </div>
    {previewImage && (
      // <div className="mb-4 max-h-1/3">
    <div className="flex justify-center mb-4 max-h-1/2">
    <img
      src={previewImage}
      alt="Preview"
      className="object-contain rounded-md drop-shadow-md"
    />
    </div>
      // </div>
    )}
  </div>
  <div className="w-1/4 pl-4 border-l">
    <h2 className="text-3xl font-bold mb-4 dark:text-white text-black">Configuration</h2>
    {Object.keys(config).map((key) => (
      <div key={key} className="mb-2">
        {/* <label
          htmlFor={`cen-${key}`}
          className="block text-gray-700 font-bold mb-1"
        >
          {key}:
        </label> */}
        {/* <select
          id={`cen-${key}`}
          value={configData[key].cen}
          onChange={(e) => handleChange(key, e.target.value)}
          className="shadow appearance-none border rounded w-full py-1 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select> */}
        <Select
          items={options}
          id={`cen-${key}`}
          value={configData[key].cen}
          label={configData[key].name}
          placeholder={configData[key].cen}
          className="max-w-xs"
          variant='bordered'
          onChange={(e) => handleChange(key, e.target.value)}
        >
          {/* {(key) => {configData[key].cen}</SelectItem>} */}
          {options.map((option) => (
            <SelectItem key={option} value={option}>
              {option}
            </SelectItem>
          ))}
        </Select>
      </div>
    ))}
    <div className="flex items-center justify-center">
      <Button
        type="submit"
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold"
      >
        Update
      </Button>
    </div>
  </div>
  </form>
  );
};

export default GifUploader;