import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ImageUploader from './ImageUploader';
import Home from './Home';
import GifUploader from './GifUploader';
import NotFound from './NotFound';
import TermsOfService from './TermsOfService';
import {Navbar, NavbarBrand, NavbarContent, NavbarItem, Link, Button} from "@nextui-org/react";


const AppRouter = () => {
    const location = useLocation();
    const activeItem = location.pathname;
    return (
        <div className="flex flex-col w-full h-dvh overflow-auto bg-blue-200 dark:bg-gray-700 dark:text-white">
        <Navbar>
            <NavbarBrand>
                <Link className="font-bold text-inherit" href="/">
                Censor-Net
                </Link>
            </NavbarBrand>
            <NavbarContent className="hidden sm:flex gap-4" justify="end">
                <NavbarItem isActive={activeItem === '/'}>
                <Link href="/" color={ activeItem === '/' ? 'primary' : 'foreground' }>
                    Home
                </Link>
                </NavbarItem>
                <NavbarItem isActive={activeItem === '/censor-image'}>
                    <Link href="/censor-image" color={ activeItem === '/censor-image' ? 'primary' : 'foreground' }>
                        Image
                    </Link>
                </NavbarItem>
                <NavbarItem isActive={activeItem === '/censor-gif'}>
                    <Link href="/censor-gif" color={ activeItem === '/censor-gif' ? 'primary' : 'foreground' }>
                        Gif <span className="items-center rounded-md bg-green-50 px-1 mt-0.5 ml-2 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">New</span>
                    </Link>
                </NavbarItem>
                <NavbarItem isActive={activeItem === '/terms'}>
                <Link href="/terms" color={ activeItem === '/terms' ? 'primary' : 'foreground' }>
                    Terms
                </Link>
                </NavbarItem>
            </NavbarContent>
        </Navbar>
        <main className="flex-1 flex flex-col w-full mt-4 justify-center items-center">
        <div className="mx-auto w-3/4 pt-4 px-4 max-h-full bg-white shadow-md rounded dark:bg-gray-800 dark:text-white">
                <Routes>
                    <Route path='/' element={<Home/>} />
                    <Route path='/censor-image' element={<ImageUploader/>} />
                    <Route path='/censor-gif' element={<GifUploader/>} />
                    <Route path='/terms' element={<TermsOfService/>} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
        </div>
        </main>
    </div>
    );
};

export default AppRouter;