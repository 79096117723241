import React from 'react';

const NotFound = () => {
  document.title = "Not found";
  return (
    <div className="flex flex-col items-center justify-center h-screen my-auto">
      <div className="text-center">
        <h1 className="text-5xl font-bold mb-2 text-gray-100">404: Page Not Found</h1>
        <p className="text-xl text-gray-300">The page you are looking for does not exist.</p>
      </div>
      <div className="mt-8">
        <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
            <a href="/">
            Go Back Home
          </a>
        </button>
      </div>
    </div>
  );
};

export default NotFound;