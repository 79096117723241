import { Image } from '@nextui-org/react';

function Home() {
  document.title = "Censor net";
  return (
    <div className="max-w-4xl mx-auto p-2 pt-6 md:p-2 lg:p-4">
      <h1>
        <title>Censor porn images - Free nudity censor</title>
      </h1>
      <main className="flex flex-col md:flex-row items-center justify-center p-2">
        <div className="md:w-1/2 mb-4 md:mb-0">
          <h1 className="text-3xl font-bold mb-4">Censor nude images</h1>
          <p className="text-lg mb-6">
            Censor Net uses AI-powered object detection technology to identify and censor nude or sexy parts of your image. Simply upload your image and choose what to censor.
          </p>
          <a href='/censor-image'>
            <button className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded">Get Started</button>
          </a>
        </div>
        <div className="md:w-1/2">
          <Image src="/image.jpg" alt="Hero Image" width={400} height={250} className="rounded" />
        </div>
      </main>
      <section className="flex flex-col md:flex-row justify-center mb-12">
        <div className="w-full md:w-1/3 mb-4 md:mb-0 p-2">
          <h2 className="text-2xl font-bold mb-2">Accurate Object Detection</h2>
          <p className="text-lg mb-4">
            The machine learning model is trained on a vast dataset of pornographic images, ensuring accurate object detection.
          </p>
        </div>
        <div className="w-full md:w-1/3 mb-4 md:mb-0 p-2">
          <h2 className="text-2xl font-bold mb-2">Easy to Use</h2>
          <p className="text-lg mb-4">
            Simply upload your image, select which parts you want censored, and download the censored image.
          </p>
        </div>
        <div className="w-full md:w-1/3 mb-4 md:mb-0 p-2">
          <h2 className="text-2xl font-bold mb-2">Free to Use</h2>
          <p className="text-lg mb-4">
            The service is completely free to use, with no registration or subscription required.
          </p>
        </div>
      </section>
    </div>
  );
}

export default Home;