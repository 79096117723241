import React from 'react';

const TermsOfService = () => {
  document.title = "Terms";
  return (
    <div>
      <div className="p-8 prose">
        <h1>Terms of Service</h1>
        <p>
          By accessing or using our website and image
          analysis service, you agree to be bound by the following terms and
          conditions.
        </p>
        <h2>1. Usage Limitations</h2>
        <p>
          To ensure fair use and maintain the performance of our service, we limit the
          number of images that can be processed per minute.
        </p>
        <h2>2. User Responsibility</h2>
        <p>
          You are solely responsible for the images you upload and the consequences
          of uploading and using them. You must ensure that the images you upload
          do not violate any applicable laws, regulations, or third-party rights.
        </p>
        <h2>3. Prohibited Content</h2>
        <p>
          You are prohibited from uploading any illegal, harmful, or offensive
          content. This includes, but is not limited to, non-consensual, violent or
          discriminatory material. Uploading images of children is prohibited.
        </p>
        <h2>4. Disclaimer of Warranties</h2>
        <p>
          Our service is provided "as is" and "as available" without warranties of
          any kind, either express or implied. We do not guarantee the accuracy,
          completeness, or timeliness of the results provided by our image analysis
          service.
        </p>
        <h2>5. Limitation of Liability</h2>
        <p>
          In no event will we be liable for any direct, indirect, incidental,
          special, consequential, or exemplary damages, including but not limited
          to, damages for loss of profits, goodwill, use, data, or other
          intangible losses.
        </p>
        <h2>6. Changes to Terms of Service</h2>
        <p>
          We reserve the right to modify these terms of service at any time without prior notice.
          Your continued use of our website and image analysis service following any changes constitutes your acceptance of the new terms of service.
      </p>
      </div>
      <div className="p-8 prose">
        <h1>Privacy Policy</h1>
        <p>
          At censor-net.com, we are committed to protecting your privacy. We do not
          collect, store, or share any personal information about our users.
        </p>
        <p>
          When you use our image analysis service, we temporarily process the images
          you upload to analyze them using our machine learning model. We do not
          store these images or any metadata associated with them, except for the
          limited period necessary to provide the service and ensure its proper
          functioning. After this period, all images and associated metadata are
          permanently deleted.
        </p>
        <p>
          We may use aggregated, non-identifiable data for analytics, research, and
          development purposes. This data is not linked to any individual user and
          cannot be used to identify you.
        </p>
        <p>
          By using our website and image analysis service, you consent to our privacy
          policy.
        </p>
      </div>
    </div>
)};

export default TermsOfService;
